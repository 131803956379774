var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Base64, SessionCrypto, hexToByteArray } from '@tonconnect/protocol';
import { TonConnectError } from "../../errors/ton-connect.error";
import { BridgeGateway } from "./bridge-gateway";
import { BridgeConnectionStorage } from "../../storage/bridge-connection-storage";
import { PROTOCOL_VERSION } from "../../resources/protocol";
export class BridgeProvider {
    constructor(storage, walletConnectionSource) {
        this.storage = storage;
        this.walletConnectionSource = walletConnectionSource;
        this.type = 'http';
        this.pendingRequests = new Map();
        this.nextRequestId = 0;
        this.session = null;
        this.bridge = null;
        this.listeners = [];
        this.connectionStorage = new BridgeConnectionStorage(storage);
    }
    static fromStorage(storage) {
        return __awaiter(this, void 0, void 0, function* () {
            const bridgeConnectionStorage = new BridgeConnectionStorage(storage);
            const connection = yield bridgeConnectionStorage.getHttpConnection();
            return new BridgeProvider(storage, connection.session.walletConnectionSource);
        });
    }
    connect(message) {
        var _a;
        (_a = this.bridge) === null || _a === void 0 ? void 0 : _a.close();
        const sessionCrypto = new SessionCrypto();
        this.session = {
            sessionCrypto,
            walletConnectionSource: this.walletConnectionSource
        };
        this.bridge = new BridgeGateway(this.storage, this.walletConnectionSource.bridgeUrl, sessionCrypto.sessionId, this.gatewayListener.bind(this), this.gatewayErrorsListener.bind(this));
        this.bridge.registerSession();
        return this.generateUniversalLink(message);
    }
    restoreConnection() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            (_a = this.bridge) === null || _a === void 0 ? void 0 : _a.close();
            const storedConnection = yield this.connectionStorage.getHttpConnection();
            if (!storedConnection) {
                return;
            }
            this.session = storedConnection.session;
            this.bridge = new BridgeGateway(this.storage, this.walletConnectionSource.bridgeUrl, storedConnection.session.sessionCrypto.sessionId, this.gatewayListener.bind(this), this.gatewayErrorsListener.bind(this));
            yield this.bridge.registerSession();
            this.listeners.forEach(listener => listener(storedConnection.connectEvent));
        });
    }
    sendRequest(request) {
        return new Promise((resolve, reject) => {
            const id = this.nextRequestId;
            this.nextRequestId++;
            if (!this.bridge || !this.session || !('walletPublicKey' in this.session)) {
                throw new TonConnectError('Trying to send bridge request without session');
            }
            const encodedRequest = this.session.sessionCrypto.encrypt(JSON.stringify(Object.assign(Object.assign({}, request), { id })), hexToByteArray(this.session.walletPublicKey));
            this.bridge.send(encodedRequest, this.session.walletPublicKey).catch(reject);
            this.pendingRequests.set(id.toString(), resolve);
        });
    }
    closeConnection() {
        var _a;
        (_a = this.bridge) === null || _a === void 0 ? void 0 : _a.close();
        this.listeners = [];
        this.session = null;
        this.bridge = null;
    }
    disconnect() {
        var _a;
        (_a = this.bridge) === null || _a === void 0 ? void 0 : _a.close();
        this.listeners = [];
        return this.removeBridgeAndSession();
    }
    listen(callback) {
        this.listeners.push(callback);
        return () => (this.listeners = this.listeners.filter(listener => listener !== callback));
    }
    gatewayListener(bridgeIncomingMessage) {
        return __awaiter(this, void 0, void 0, function* () {
            const walletMessage = JSON.parse(this.session.sessionCrypto.decrypt(Base64.decode(bridgeIncomingMessage.message).toUint8Array(), hexToByteArray(bridgeIncomingMessage.from)));
            if (!('event' in walletMessage)) {
                const id = walletMessage.id.toString();
                const resolve = this.pendingRequests.get(id);
                if (!resolve) {
                    throw new TonConnectError(`Response id ${id} doesn't match any request's id`);
                }
                resolve(walletMessage);
                this.pendingRequests.delete(id);
                return;
            }
            if (walletMessage.event === 'connect') {
                yield this.updateSession(walletMessage, bridgeIncomingMessage.from);
            }
            if (walletMessage.event === 'disconnect') {
                yield this.removeBridgeAndSession();
            }
            this.listeners.forEach(listener => listener(walletMessage));
        });
    }
    gatewayErrorsListener(e) {
        return __awaiter(this, void 0, void 0, function* () {
            throw new TonConnectError(`Bridge error ${JSON.stringify(e)}`);
        });
    }
    updateSession(connectEvent, walletPublicKey) {
        return __awaiter(this, void 0, void 0, function* () {
            this.session = Object.assign(Object.assign({}, this.session), { walletPublicKey });
            const tonAddrItem = connectEvent.payload.items.find(item => item.name === 'ton_addr');
            const connectEventToSave = Object.assign(Object.assign({}, connectEvent), { payload: Object.assign(Object.assign({}, connectEvent.payload), { items: [tonAddrItem] }) });
            yield this.connectionStorage.storeConnection({
                type: 'http',
                session: this.session,
                connectEvent: connectEventToSave
            });
        });
    }
    removeBridgeAndSession() {
        return __awaiter(this, void 0, void 0, function* () {
            this.session = null;
            this.bridge = null;
            yield this.connectionStorage.removeConnection();
        });
    }
    generateUniversalLink(message) {
        const url = new URL(this.walletConnectionSource.universalLink);
        url.searchParams.append('v', PROTOCOL_VERSION.toString());
        url.searchParams.append('id', this.session.sessionCrypto.sessionId);
        url.searchParams.append('r', JSON.stringify(message));
        return url.toString();
    }
}
