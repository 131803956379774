var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SessionCrypto } from '@tonconnect/protocol';
import { TonConnectError } from "../errors";
export class BridgeConnectionStorage {
    constructor(storage) {
        this.storage = storage;
        this.storeKey = 'ton-connect-storage_bridge-connection';
    }
    storeConnection(connection) {
        return __awaiter(this, void 0, void 0, function* () {
            if (connection.type === 'injected') {
                return this.storage.setItem(this.storeKey, JSON.stringify(connection));
            }
            const rawSession = {
                sessionKeyPair: connection.session.sessionCrypto.stringifyKeypair(),
                walletPublicKey: connection.session.walletPublicKey,
                walletConnectionSource: connection.session.walletConnectionSource
            };
            const rawConnection = {
                type: 'http',
                connectEvent: connection.connectEvent,
                session: rawSession
            };
            return this.storage.setItem(this.storeKey, JSON.stringify(rawConnection));
        });
    }
    removeConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.storage.removeItem(this.storeKey);
        });
    }
    getConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            const stored = yield this.storage.getItem(this.storeKey);
            if (!stored) {
                return null;
            }
            const connection = JSON.parse(stored);
            if (connection.type === 'injected') {
                return connection;
            }
            const sessionCrypto = new SessionCrypto(connection.session.sessionKeyPair);
            return {
                type: 'http',
                connectEvent: connection.connectEvent,
                session: {
                    sessionCrypto,
                    walletConnectionSource: connection.session.walletConnectionSource,
                    walletPublicKey: connection.session.walletPublicKey
                }
            };
        });
    }
    getHttpConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            const connection = yield this.getConnection();
            if (!connection) {
                throw new TonConnectError('Trying to read HTTP connection source while nothing is stored');
            }
            if (connection.type === 'injected') {
                throw new TonConnectError('Trying to read HTTP connection source while injected connection is stored');
            }
            return connection;
        });
    }
    getInjectedConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            const connection = yield this.getConnection();
            if (!connection) {
                throw new TonConnectError('Trying to read Injected bridge connection source while nothing is stored');
            }
            if ((connection === null || connection === void 0 ? void 0 : connection.type) === 'http') {
                throw new TonConnectError('Trying to read Injected bridge connection source while HTTP connection is stored');
            }
            return connection;
        });
    }
    storedConnectionType() {
        return __awaiter(this, void 0, void 0, function* () {
            const stored = yield this.storage.getItem(this.storeKey);
            if (!stored) {
                return null;
            }
            const connection = JSON.parse(stored);
            return connection.type;
        });
    }
}
