import { BadRequestError, UnknownAppError, UserRejectsError } from "../errors";
import { ManifestContentErrorError } from "../errors/protocol/events/connect/manifest-content-error.error";
import { ManifestNotFoundError } from "../errors/protocol/events/connect/manifest-not-found.error";
import { UnknownError } from "../errors/unknown.error";
import { CONNECT_EVENT_ERROR_CODES } from '@tonconnect/protocol';
const connectEventErrorsCodes = {
    [CONNECT_EVENT_ERROR_CODES.UNKNOWN_ERROR]: UnknownError,
    [CONNECT_EVENT_ERROR_CODES.USER_REJECTS_ERROR]: UserRejectsError,
    [CONNECT_EVENT_ERROR_CODES.BAD_REQUEST_ERROR]: BadRequestError,
    [CONNECT_EVENT_ERROR_CODES.UNKNOWN_APP_ERROR]: UnknownAppError,
    [CONNECT_EVENT_ERROR_CODES.MANIFEST_NOT_FOUND_ERROR]: ManifestNotFoundError,
    [CONNECT_EVENT_ERROR_CODES.MANIFEST_CONTENT_ERROR]: ManifestContentErrorError
};
class ConnectErrorsParser {
    parseError(error) {
        let ErrorConstructor = UnknownError;
        if (error.code in connectEventErrorsCodes) {
            ErrorConstructor = connectEventErrorsCodes[error.code] || UnknownError;
        }
        return new ErrorConstructor(error.message);
    }
}
export const connectErrorsParser = new ConnectErrorsParser();
