var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class HttpBridgeGatewayStorage {
    constructor(storage) {
        this.storage = storage;
        this.storeKey = 'ton-connect-storage_http-bridge-gateway';
    }
    storeLastEventId(lastEventId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.storage.setItem(this.storeKey, lastEventId);
        });
    }
    removeLastEventId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.storage.removeItem(this.storeKey);
        });
    }
    getLastEventId() {
        return __awaiter(this, void 0, void 0, function* () {
            const stored = yield this.storage.getItem(this.storeKey);
            if (!stored) {
                return null;
            }
            return stored;
        });
    }
}
