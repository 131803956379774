import { TonConnectError } from "../../../ton-connect.error";
/**
 * Thrown when user rejects the action in the wallet.
 */
export class UserRejectsError extends TonConnectError {
    constructor(...args) {
        super(...args);
        Object.setPrototypeOf(this, UserRejectsError.prototype);
    }
}
