/**
 * Base class for TonConnect errors. You can check if the error was triggered by the @tonconnect/sdk using `err instanceof TonConnectError`.
 */
export class TonConnectError extends Error {
    constructor(message, options) {
        if (message) {
            message = TonConnectError.prefix + ' ' + message;
        }
        super(message, options);
        Object.setPrototypeOf(this, TonConnectError.prototype);
    }
}
TonConnectError.prefix = '[TON_CONNECT_SDK_ERROR]';
