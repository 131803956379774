var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { DappMetadataError } from "./errors/dapp/dapp-metadata.error";
import { ManifestContentErrorError } from "./errors/protocol/events/connect/manifest-content-error.error";
import { ManifestNotFoundError } from "./errors/protocol/events/connect/manifest-not-found.error";
import { TonConnectError } from "./errors/ton-connect.error";
import { WalletAlreadyConnectedError } from "./errors/wallet/wallet-already-connected.error";
import { WalletNotConnectedError } from "./errors/wallet/wallet-not-connected.error";
import { WalletNotSupportFeatureError } from "./errors/wallet/wallet-not-support-feature.error";
import { isWalletConnectionSourceJS } from "./models/wallet/wallet-connection-source";
import { connectErrorsParser } from "./parsers/connect-errors-parser";
import { sendTransactionParser } from "./parsers/send-transaction-parser";
import { BridgeProvider } from "./provider/bridge/bridge-provider";
import { InjectedProvider } from "./provider/injected/injected-provider";
import { BridgeConnectionStorage } from "./storage/bridge-connection-storage";
import { DefaultStorage } from "./storage/default-storage";
import { getWebPageManifest } from "./utils/web-api";
import { WalletsListManager } from "./wallets-list-manager";
export class TonConnect {
    constructor(options) {
        this.walletsList = new WalletsListManager();
        this._wallet = null;
        this.provider = null;
        this.statusChangeSubscriptions = [];
        this.statusChangeErrorSubscriptions = [];
        this.dappSettings = {
            manifestUrl: (options === null || options === void 0 ? void 0 : options.manifestUrl) || getWebPageManifest(),
            storage: (options === null || options === void 0 ? void 0 : options.storage) || new DefaultStorage()
        };
        this.walletsList = new WalletsListManager(options === null || options === void 0 ? void 0 : options.walletsListSource);
        if (!this.dappSettings.manifestUrl) {
            throw new DappMetadataError('Dapp tonconnect-manifest.json must be specified if window.location.origin is undefined. See more https://github.com/ton-connect/docs/blob/main/requests-responses.md#app-manifest');
        }
        this.bridgeConnectionStorage = new BridgeConnectionStorage(this.dappSettings.storage);
    }
    /**
     * Returns available wallets list.
     */
    static getWallets() {
        return this.walletsList.getWallets();
    }
    /**
     * Shows if the wallet is connected right now.
     */
    get connected() {
        return this._wallet !== null;
    }
    /**
     * Current connected account or null if no account is connected.
     */
    get account() {
        var _a;
        return ((_a = this._wallet) === null || _a === void 0 ? void 0 : _a.account) || null;
    }
    /**
     * Current connected wallet or null if no account is connected.
     */
    get wallet() {
        return this._wallet;
    }
    set wallet(value) {
        this._wallet = value;
        this.statusChangeSubscriptions.forEach(callback => callback(this._wallet));
    }
    /**
     * Returns available wallets list.
     */
    getWallets() {
        return this.walletsList.getWallets();
    }
    /**
     * Allows to subscribe to connection status changes and handle connection errors.
     * @param callback will be called after connections status changes with actual wallet or null.
     * @param errorsHandler (optional) will be called with some instance of TonConnectError when connect error is received.
     * @returns unsubscribe callback.
     */
    onStatusChange(callback, errorsHandler) {
        this.statusChangeSubscriptions.push(callback);
        if (errorsHandler) {
            this.statusChangeErrorSubscriptions.push(errorsHandler);
        }
        return () => {
            this.statusChangeSubscriptions = this.statusChangeSubscriptions.filter(item => item !== callback);
            if (errorsHandler) {
                this.statusChangeErrorSubscriptions = this.statusChangeErrorSubscriptions.filter(item => item !== errorsHandler);
            }
        };
    }
    connect(wallet, request) {
        var _a;
        if (this.connected) {
            throw new WalletAlreadyConnectedError();
        }
        (_a = this.provider) === null || _a === void 0 ? void 0 : _a.closeConnection();
        this.provider = this.createProvider(wallet);
        return this.provider.connect(this.createConnectRequest(request));
    }
    /**
     * Try to restore existing session and reconnect to the corresponding wallet. Call it immediately when your app is loaded.
     */
    restoreConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            const [bridgeConnectionType, embeddedWallet] = yield Promise.all([
                this.bridgeConnectionStorage.storedConnectionType(),
                this.walletsList.getEmbeddedWallet()
            ]);
            switch (bridgeConnectionType) {
                case 'http':
                    this.provider = yield BridgeProvider.fromStorage(this.dappSettings.storage);
                    break;
                case 'injected':
                    this.provider = yield InjectedProvider.fromStorage(this.dappSettings.storage);
                    break;
                default:
                    if (embeddedWallet) {
                        this.provider = yield this.createProvider(embeddedWallet);
                    }
                    else {
                        return;
                    }
            }
            this.provider.listen(this.walletEventsListener.bind(this));
            return this.provider.restoreConnection();
        });
    }
    /**
     * Asks connected wallet to sign and send the transaction.
     * @param transaction transaction to send.
     * @returns signed transaction boc that allows you to find the transaction in the blockchain.
     * If user rejects transaction, method will throw the corresponding error.
     */
    sendTransaction(transaction) {
        return __awaiter(this, void 0, void 0, function* () {
            this.checkConnection();
            this.checkFeatureSupport('SendTransaction');
            const { validUntil } = transaction, tx = __rest(transaction, ["validUntil"]);
            const response = yield this.provider.sendRequest(sendTransactionParser.convertToRpcRequest(Object.assign(Object.assign({}, tx), { valid_until: validUntil })));
            if (sendTransactionParser.isError(response)) {
                return sendTransactionParser.parseAndThrowError(response);
            }
            return sendTransactionParser.convertFromRpcResponse(response);
        });
    }
    /**
     * Disconnect form thw connected wallet and drop current session.
     */
    disconnect() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.connected) {
                throw new WalletNotConnectedError();
            }
            yield this.provider.disconnect();
            this.onWalletDisconnected();
        });
    }
    createProvider(wallet) {
        let provider;
        if (isWalletConnectionSourceJS(wallet)) {
            provider = new InjectedProvider(this.dappSettings.storage, wallet.jsBridgeKey);
        }
        else {
            provider = new BridgeProvider(this.dappSettings.storage, wallet);
        }
        provider.listen(this.walletEventsListener.bind(this));
        return provider;
    }
    walletEventsListener(e) {
        switch (e.event) {
            case 'connect':
                this.onWalletConnected(e.payload);
                break;
            case 'connect_error':
                this.onWalletConnectError(e.payload);
                break;
            case 'disconnect':
                this.onWalletDisconnected();
        }
    }
    onWalletConnected(connectEvent) {
        const tonAccountItem = connectEvent.items.find(item => item.name === 'ton_addr');
        const tonProofItem = connectEvent.items.find(item => item.name === 'ton_proof');
        if (!tonAccountItem) {
            throw new TonConnectError('ton_addr connection item was not found');
        }
        const wallet = {
            device: connectEvent.device,
            provider: this.provider.type,
            account: {
                address: tonAccountItem.address,
                chain: tonAccountItem.network,
                walletStateInit: tonAccountItem.walletStateInit
            }
        };
        if (tonProofItem) {
            wallet.connectItems = {
                tonProof: tonProofItem
            };
        }
        this.wallet = wallet;
    }
    onWalletConnectError(connectEventError) {
        const error = connectErrorsParser.parseError(connectEventError);
        this.statusChangeErrorSubscriptions.forEach(errorsHandler => errorsHandler(error));
        console.debug(error);
        if (error instanceof ManifestNotFoundError || error instanceof ManifestContentErrorError) {
            console.error(error);
            throw error;
        }
    }
    onWalletDisconnected() {
        this.wallet = null;
    }
    checkConnection() {
        if (!this.connected) {
            throw new WalletNotConnectedError();
        }
    }
    checkFeatureSupport(feature) {
        var _a;
        if (!((_a = this.wallet) === null || _a === void 0 ? void 0 : _a.device.features.includes(feature))) {
            throw new WalletNotSupportFeatureError();
        }
    }
    createConnectRequest(request) {
        const items = [
            {
                name: 'ton_addr'
            }
        ];
        if (request === null || request === void 0 ? void 0 : request.tonProof) {
            items.push({
                name: 'ton_proof',
                payload: request.tonProof
            });
        }
        return {
            manifestUrl: this.dappSettings.manifestUrl,
            items
        };
    }
}
TonConnect.walletsList = new WalletsListManager();
/**
 * Check if specified wallet is injected and available to use with the app.
 * @param walletJSKey target wallet's js bridge key.
 */
TonConnect.isWalletInjected = (walletJSKey) => InjectedProvider.isWalletInjected(walletJSKey);
/**
 * Check if the app is opened inside specified wallet's browser.
 * @param walletJSKey target wallet's js bridge key.
 */
TonConnect.isInsideWalletBrowser = (walletJSKey) => InjectedProvider.isInsideWalletBrowser(walletJSKey);
