var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Base64, isNode } from '@tonconnect/protocol';
import { TonConnectError } from "../../errors";
import { HttpBridgeGatewayStorage } from "../../storage/http-bridge-gateway-storage";
import { addPathToUrl } from "../../utils/url";
if (isNode()) {
    try {
        eval("global.EventSource = require('eventsource')");
        eval("global.fetch = require('node-fetch')");
    }
    catch (err) {
        console.error(err);
    }
}
export class BridgeGateway {
    constructor(storage, bridgeUrl, sessionId, listener, errorsListener) {
        this.bridgeUrl = bridgeUrl;
        this.sessionId = sessionId;
        this.listener = listener;
        this.errorsListener = errorsListener;
        this.ssePath = 'events';
        this.postPath = 'message';
        this.heartbeatMessage = 'heartbeat';
        this.defaultTtl = 300;
        this.isClosed = false;
        this.bridgeGatewayStorage = new HttpBridgeGatewayStorage(storage);
    }
    registerSession() {
        return __awaiter(this, void 0, void 0, function* () {
            const url = new URL(addPathToUrl(this.bridgeUrl, this.ssePath));
            url.searchParams.append('client_id', this.sessionId);
            const lastEventId = yield this.bridgeGatewayStorage.getLastEventId();
            if (lastEventId) {
                url.searchParams.append('last_event_id', lastEventId);
            }
            this.eventSource = new EventSource(url.toString());
            return new Promise((resolve, reject) => {
                this.eventSource.onerror = reject;
                this.eventSource.onopen = () => {
                    this.eventSource.onerror = this.errorsHandler.bind(this);
                    this.eventSource.onmessage = this.messagesHandler.bind(this);
                    resolve();
                };
            });
        });
    }
    send(message, receiver, ttl) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = new URL(addPathToUrl(this.bridgeUrl, this.postPath));
            url.searchParams.append('client_id', this.sessionId);
            url.searchParams.append('to', receiver);
            url.searchParams.append('ttl', (ttl || this.defaultTtl).toString());
            yield fetch(url, {
                method: 'post',
                body: Base64.encode(message)
            });
        });
    }
    close() {
        var _a;
        this.isClosed = true;
        (_a = this.eventSource) === null || _a === void 0 ? void 0 : _a.close();
    }
    errorsHandler(e) {
        var _a, _b;
        if (!this.isClosed) {
            if (((_a = this.eventSource) === null || _a === void 0 ? void 0 : _a.readyState) === EventSource.CLOSED) {
                this.eventSource.close();
                this.registerSession();
                return;
            }
            if (((_b = this.eventSource) === null || _b === void 0 ? void 0 : _b.readyState) === EventSource.CONNECTING) {
                console.debug('[TON_CONNET_SDK_ERROR]: Bridge error', JSON.stringify(e));
                return;
            }
            this.errorsListener(e);
        }
    }
    messagesHandler(e) {
        return __awaiter(this, void 0, void 0, function* () {
            if (e.data === this.heartbeatMessage) {
                return;
            }
            yield this.bridgeGatewayStorage.storeLastEventId(e.lastEventId);
            if (!this.isClosed) {
                let bridgeIncomingMessage;
                try {
                    bridgeIncomingMessage = JSON.parse(e.data);
                }
                catch (e) {
                    throw new TonConnectError(`Bridge message parse failed, message ${e.data}`);
                }
                this.listener(bridgeIncomingMessage);
            }
        });
    }
}
