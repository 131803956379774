import { TonConnectError } from "../ton-connect.error";
/**
 * Thrown when `Storage` was not specified in the `DappMetadata` and default `localStorage` was not detected in the environment.
 */
export class LocalstorageNotFoundError extends TonConnectError {
    constructor(...args) {
        super(...args);
        Object.setPrototypeOf(this, LocalstorageNotFoundError.prototype);
    }
}
