var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FetchWalletsError } from "./errors/wallets-manager/fetch-wallets.error";
import { isWalletInfoInjected } from "./models/wallet/wallet-info";
import { InjectedProvider } from "./provider/injected/injected-provider";
export class WalletsListManager {
    constructor(walletsListSource) {
        this.walletsListCache = null;
        this.walletsListSource = 'https://raw.githubusercontent.com/ton-connect/wallets-list/main/wallets.json';
        if (walletsListSource) {
            this.walletsListSource = walletsListSource;
        }
    }
    getWallets() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.walletsListCache) {
                this.walletsListCache = this.fetchWalletsList();
                this.walletsListCache.catch(() => (this.walletsListCache = null));
            }
            return this.walletsListCache;
        });
    }
    getEmbeddedWallet() {
        return __awaiter(this, void 0, void 0, function* () {
            const walletsList = yield this.getWallets();
            const embeddedWallets = walletsList.filter(item => isWalletInfoInjected(item) && item.embedded);
            if (embeddedWallets.length !== 1) {
                return null;
            }
            return embeddedWallets[0];
        });
    }
    fetchWalletsList() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const walletsResponse = yield fetch(this.walletsListSource);
                const walletsList = yield walletsResponse.json();
                if (!Array.isArray(walletsList) ||
                    !walletsList.every(wallet => this.isCorrectWalletConfigDTO(wallet))) {
                    throw new FetchWalletsError('Wrong wallets list format');
                }
                return this.walletConfigDTOListToWalletConfigList(walletsList);
            }
            catch (e) {
                throw new FetchWalletsError(e);
            }
        });
    }
    walletConfigDTOListToWalletConfigList(walletConfigDTO) {
        return walletConfigDTO.map(walletConfigDTO => {
            const walletConfig = {
                name: walletConfigDTO.name,
                imageUrl: walletConfigDTO.image,
                aboutUrl: walletConfigDTO.about_url,
                tondns: walletConfigDTO.tondns
            };
            walletConfigDTO.bridge.forEach(bridge => {
                if (bridge.type === 'sse') {
                    walletConfig.bridgeUrl = bridge.url;
                    walletConfig.universalLink =
                        walletConfigDTO.universal_url;
                    walletConfig.deepLink = walletConfigDTO.deepLink;
                }
                if (bridge.type === 'js') {
                    const jsBridgeKey = bridge.key;
                    walletConfig.jsBridgeKey = jsBridgeKey;
                    walletConfig.injected =
                        InjectedProvider.isWalletInjected(jsBridgeKey);
                    walletConfig.embedded =
                        InjectedProvider.isInsideWalletBrowser(jsBridgeKey);
                }
            });
            return walletConfig;
        });
    }
    isCorrectWalletConfigDTO(value) {
        if (!value || !(typeof value === 'object')) {
            return false;
        }
        const containsName = 'name' in value;
        const containsImage = 'image' in value;
        const containsAbout = 'about_url' in value;
        if (!containsName || !containsImage || !containsAbout) {
            return false;
        }
        if (!('bridge' in value) ||
            !Array.isArray(value.bridge) ||
            !value.bridge.length) {
            return false;
        }
        const bridge = value.bridge;
        if (bridge.some(item => !item || typeof item !== 'object' || !('type' in item))) {
            return false;
        }
        const sseBridge = bridge.find(item => item.type === 'sse');
        if (sseBridge) {
            if (!('url' in sseBridge) ||
                !sseBridge.url ||
                !value.universal_url) {
                return false;
            }
        }
        const jsBridge = bridge.find(item => item.type === 'js');
        if (jsBridge) {
            if (!('key' in jsBridge) || !jsBridge.key) {
                return false;
            }
        }
        return true;
    }
}
