import { TonConnectError } from "../ton-connect.error";
/**
 * Thrown when passed DappMetadata is in incorrect format.
 */
export class DappMetadataError extends TonConnectError {
    constructor(...args) {
        super(...args);
        Object.setPrototypeOf(this, DappMetadataError.prototype);
    }
}
