import { TonConnectError } from "../ton-connect.error";
/**
 * Thrown when passed hex is in incorrect format.
 */
export class ParseHexError extends TonConnectError {
    constructor(...args) {
        super(...args);
        Object.setPrototypeOf(this, ParseHexError.prototype);
    }
}
