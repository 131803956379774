import { TonConnectError } from "../ton-connect.error";
/**
 * Thrown when wallet doesn't support requested feature method.
 */
export class WalletNotSupportFeatureError extends TonConnectError {
    constructor(...args) {
        super(...args);
        Object.setPrototypeOf(this, WalletNotSupportFeatureError.prototype);
    }
}
