import { TonConnectError } from "../ton-connect.error";
/**
 * Thrown when there is an attempt to connect to the injected wallet while it is not exists in the webpage.
 */
export class WalletNotInjectedError extends TonConnectError {
    constructor(...args) {
        super(...args);
        Object.setPrototypeOf(this, WalletNotInjectedError.prototype);
    }
}
