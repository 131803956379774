var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LocalstorageNotFoundError } from "../errors/storage/localstorage-not-found.error";
import { getWindow } from "../utils/web-api";
export class DefaultStorage {
    constructor() {
        const window = getWindow();
        if (!(window === null || window === void 0 ? void 0 : window.localStorage)) {
            throw new LocalstorageNotFoundError();
        }
        this.window = window;
    }
    getItem(key) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve(this.window.localStorage.getItem(key));
        });
    }
    removeItem(key) {
        return __awaiter(this, void 0, void 0, function* () {
            this.window.localStorage.removeItem(key);
            return Promise.resolve();
        });
    }
    setItem(key, value) {
        this.window.localStorage.setItem(key, value);
        return Promise.resolve();
    }
}
