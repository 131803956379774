export function getWindow() {
    if (typeof window === 'undefined') {
        return undefined;
    }
    return window;
}
export function getDocument() {
    if (typeof document === 'undefined') {
        return undefined;
    }
    return document;
}
export function getWebPageManifest() {
    var _a;
    const origin = (_a = getWindow()) === null || _a === void 0 ? void 0 : _a.location.origin;
    if (origin) {
        return origin + '/tonconnect-manifest.json';
    }
    return '';
}
