import { concatUint8Arrays, hexToByteArray, splitToUint8Arrays, toHexString, isNode } from '../utils';
import nacl from 'tweetnacl';
if (isNode()) {
    try {
        eval("global.crypto = require('crypto').webcrypto");
    }
    catch (err) { }
}
export class SessionCrypto {
    constructor(keyPair) {
        this.nonceLength = 24;
        this.keyPair = keyPair ? this.createKeypairFromString(keyPair) : this.createKeypair();
        this.sessionId = toHexString(this.keyPair.publicKey);
    }
    createKeypair() {
        return nacl.box.keyPair();
    }
    createKeypairFromString(keyPair) {
        return {
            publicKey: hexToByteArray(keyPair.publicKey),
            secretKey: hexToByteArray(keyPair.secretKey)
        };
    }
    createNonce() {
        const buffer = new Uint8Array(this.nonceLength);
        return crypto.getRandomValues(buffer);
    }
    encrypt(message, receiverPublicKey) {
        const encodedMessage = new TextEncoder().encode(message);
        const nonce = this.createNonce();
        const encrypted = nacl.box(encodedMessage, nonce, receiverPublicKey, this.keyPair.secretKey);
        return concatUint8Arrays(nonce, encrypted);
    }
    decrypt(message, senderPublicKey) {
        const [nonce, internalMessage] = splitToUint8Arrays(message, this.nonceLength);
        const decrypted = nacl.box.open(internalMessage, nonce, senderPublicKey, this.keyPair.secretKey);
        if (!decrypted) {
            throw new Error(`Decryption error: \n message: ${message.toString()} \n sender pubkey: ${senderPublicKey.toString()} \n keypair pubkey: ${this.keyPair.publicKey.toString()} \n keypair secretkey: ${this.keyPair.secretKey.toString()}`);
        }
        return new TextDecoder().decode(decrypted);
    }
    stringifyKeypair() {
        return {
            publicKey: toHexString(this.keyPair.publicKey),
            secretKey: toHexString(this.keyPair.secretKey)
        };
    }
}
