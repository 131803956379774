import { TonConnectError } from "./ton-connect.error";
/**
 * Unhanded unknown error.
 */
export class UnknownError extends TonConnectError {
    constructor(...args) {
        super(...args);
        Object.setPrototypeOf(this, UnknownError.prototype);
    }
}
