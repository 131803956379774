import { TonConnectError } from "../ton-connect.error";
/**
 * Thrown when passed address is in incorrect format.
 */
export class WrongAddressError extends TonConnectError {
    constructor(...args) {
        super(...args);
        Object.setPrototypeOf(this, WrongAddressError.prototype);
    }
}
