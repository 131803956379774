import { TonConnectError } from "../../../ton-connect.error";
/**
 * Thrown when wallet can't get manifest by passed manifestUrl.
 */
export class ManifestNotFoundError extends TonConnectError {
    constructor(message) {
        super(message || '' + ManifestNotFoundError.additionalMessage);
        Object.setPrototypeOf(this, ManifestNotFoundError.prototype);
    }
}
ManifestNotFoundError.additionalMessage = '\nManifest not found. Make sure you added `tonconnect-manifest.json` to the root of your app or passed correct manifestUrl. See more https://github.com/ton-connect/docs/blob/main/requests-responses.md#app-manifest';
