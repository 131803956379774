import { TonConnectError } from "../ton-connect.error";
/**
 * Thrown when wallet connection called but wallet already connected. To avoid the error, disconnect the wallet before doing a new connection.
 */
export class WalletAlreadyConnectedError extends TonConnectError {
    constructor(...args) {
        super(...args);
        Object.setPrototypeOf(this, WalletAlreadyConnectedError.prototype);
    }
}
