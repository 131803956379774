import { SEND_TRANSACTION_ERROR_CODES } from '@tonconnect/protocol';
import { BadRequestError, UnknownAppError, UserRejectsError } from "../errors";
import { UnknownError } from "../errors/unknown.error";
import { RpcParser } from "./rpc-parser";
const sendTransactionErrors = {
    [SEND_TRANSACTION_ERROR_CODES.UNKNOWN_ERROR]: UnknownError,
    [SEND_TRANSACTION_ERROR_CODES.USER_REJECTS_ERROR]: UserRejectsError,
    [SEND_TRANSACTION_ERROR_CODES.BAD_REQUEST_ERROR]: BadRequestError,
    [SEND_TRANSACTION_ERROR_CODES.UNKNOWN_APP_ERROR]: UnknownAppError
};
class SendTransactionParser extends RpcParser {
    convertToRpcRequest(request) {
        return {
            method: 'sendTransaction',
            params: [JSON.stringify(request)]
        };
    }
    parseAndThrowError(response) {
        let ErrorConstructor = UnknownError;
        if (response.error.code in sendTransactionErrors) {
            ErrorConstructor = sendTransactionErrors[response.error.code] || UnknownError;
        }
        throw new ErrorConstructor(response.error.message);
    }
    convertFromRpcResponse(rpcResponse) {
        return {
            boc: rpcResponse.result
        };
    }
}
export const sendTransactionParser = new SendTransactionParser();
