var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { WalletNotInjectedError } from "../../errors/wallet/wallet-not-injected.error";
import { BridgeConnectionStorage } from "../../storage/bridge-connection-storage";
import { getWindow } from "../../utils/web-api";
import { PROTOCOL_VERSION } from "../../resources/protocol";
export class InjectedProvider {
    constructor(storage, injectedWalletKey) {
        this.injectedWalletKey = injectedWalletKey;
        this.type = 'injected';
        this.unsubscribeCallback = null;
        this.listenSubscriptions = false;
        this.listeners = [];
        const window = InjectedProvider.window;
        if (!InjectedProvider.isWindowContainsWallet(window, injectedWalletKey)) {
            throw new WalletNotInjectedError();
        }
        this.connectionStorage = new BridgeConnectionStorage(storage);
        this.injectedWallet = window[injectedWalletKey].tonconnect;
    }
    static fromStorage(storage) {
        return __awaiter(this, void 0, void 0, function* () {
            const bridgeConnectionStorage = new BridgeConnectionStorage(storage);
            const connection = yield bridgeConnectionStorage.getInjectedConnection();
            return new InjectedProvider(storage, connection.jsBridgeKey);
        });
    }
    static isWalletInjected(injectedWalletKey) {
        return InjectedProvider.isWindowContainsWallet(this.window, injectedWalletKey);
    }
    static isInsideWalletBrowser(injectedWalletKey) {
        if (InjectedProvider.isWindowContainsWallet(this.window, injectedWalletKey)) {
            return this.window[injectedWalletKey].tonconnect.isWalletBrowser;
        }
        return false;
    }
    static isWindowContainsWallet(window, injectedWalletKey) {
        return (!!window &&
            injectedWalletKey in window &&
            typeof window[injectedWalletKey] === 'object' &&
            'tonconnect' in window[injectedWalletKey]);
    }
    connect(message) {
        this._connect(PROTOCOL_VERSION, message);
    }
    restoreConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const connectEvent = yield this.injectedWallet.restoreConnection();
                if (connectEvent.event === 'connect') {
                    this.makeSubscriptions();
                    this.listeners.forEach(listener => listener(connectEvent));
                }
                else {
                    yield this.connectionStorage.removeConnection();
                }
            }
            catch (e) {
                yield this.connectionStorage.removeConnection();
                console.error(e);
            }
        });
    }
    closeConnection() {
        if (this.listenSubscriptions) {
            this.injectedWallet.disconnect();
        }
        this.closeAllListeners();
    }
    disconnect() {
        this.closeAllListeners();
        this.injectedWallet.disconnect();
        return this.connectionStorage.removeConnection();
    }
    closeAllListeners() {
        var _a;
        this.listenSubscriptions = false;
        this.listeners = [];
        (_a = this.unsubscribeCallback) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    listen(eventsCallback) {
        this.listeners.push(eventsCallback);
        return () => (this.listeners = this.listeners.filter(listener => listener !== eventsCallback));
    }
    sendRequest(request) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.injectedWallet.send(Object.assign(Object.assign({}, request), { id: '0' }));
        });
    }
    _connect(protocolVersion, message) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const connectEvent = yield this.injectedWallet.connect(protocolVersion, message);
                if (connectEvent.event === 'connect') {
                    yield this.updateSession();
                    this.makeSubscriptions();
                }
                this.listeners.forEach(listener => listener(connectEvent));
            }
            catch (e) {
                console.debug(e);
                const connectEventError = {
                    event: 'connect_error',
                    payload: {
                        code: 0,
                        message: e === null || e === void 0 ? void 0 : e.toString()
                    }
                };
                this.listeners.forEach(listener => listener(connectEventError));
            }
        });
    }
    makeSubscriptions() {
        this.listenSubscriptions = true;
        this.unsubscribeCallback = this.injectedWallet.listen(e => {
            if (this.listenSubscriptions) {
                this.listeners.forEach(listener => listener(e));
            }
            if (e.event === 'disconnect') {
                this.disconnect();
            }
        });
    }
    updateSession() {
        return this.connectionStorage.storeConnection({
            type: 'injected',
            jsBridgeKey: this.injectedWalletKey
        });
    }
}
InjectedProvider.window = getWindow();
