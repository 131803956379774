import { TonConnectError } from "../ton-connect.error";
/**
 * Thrown when an error occurred while fetching the wallets list.
 */
export class FetchWalletsError extends TonConnectError {
    constructor(...args) {
        super(...args);
        Object.setPrototypeOf(this, FetchWalletsError.prototype);
    }
}
